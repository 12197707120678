import React, { useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryState } from "providers/InventoryProvider";
import {
  useConservationDispatch,
  useConservationState,
} from "providers/ConservationProvider";
import { FormWrapper } from "components";
import { ConfirmationDialog } from "lib";
import Autocomplete from "lib/Select/Autocomplete";
import { useConservationStatuses } from "api/conservation/useConservationStatuses";
import { useConservationTypes } from "api/conservation/useConservationTypes";
import FieldsAndSubfields from "../FieldsAndSubfields";
import DateRangeInputs from "components/Forms/DateRangeInputs";
import { IUrlParams } from "types";
import { useQueryClient } from "@tanstack/react-query";

interface ConservationPost {
  cid: string;
  conservationStatusId: string;
  isCarbonCredit: boolean;
  conservationTypeId: string;
  fieldIds: string;
  subfieldIds: string;
  beginOnUtc: string;
  endOnUtc: string;
  geometry: string;
  description: string;
  gpsSourceTypeId: "e9aff444-911c-40a3-8222-614d70162d38";
}

const defaultValues = {
  cid: "",
  conservationStatusId: "",
  conservationTypeId: "",
  fieldIds: "",
  subfieldIds: "",
  beginOnUtc: "",
  endOnUtc: "",
  geometry: "",
  description: "",
  gpsSourceTypeId: "e9aff444-911c-40a3-8222-614d70162d38",
};

function dateRangeOverlaps(
  a_start: Date,
  a_end: Date,
  b_start: Date,
  b_end: Date
) {
  if (a_end < b_start || a_start > b_end) {
    return false;
  }
  if (a_start < b_start && b_start < a_end) return true; // b starts in a
  if (a_start < b_end && b_end < a_end) return true; // b ends in a
  if (a_start < b_start && b_end < a_end) return true; // b in a
  if (b_start < a_start && a_end < b_end) return true; // a in b
  return false;
}

function parseIdsJSON(ids: string) {
  try {
    return JSON.parse(ids);
  } catch (e) {
    return [];
  }
}

export default function ConservationForm() {
  const history = useHistory();
  const alertRef = useRef();
  const { t } = useTranslation();
  const { editId } = useParams<IUrlParams>();
  const { drawData } = useInventoryState();
  const { org, rootUrl, seasons, season } = useOrgState();
  const { currentEditFtr } = useConservationState();
  const [showSeasonAlert, setShowSeasonAlert] = useState<{
    data: ConservationPost;
    message: React.ReactNode;
  }>();
  const queryClient = useQueryClient();
  const { deleteState, saveState } = useConservationState();
  const statusesQ = useConservationStatuses(org);
  const typesQ = useConservationTypes(org);
  const {
    saveConservationArea,
    deleteConservationArea,
  } = useConservationDispatch();
  const methods = useForm({
    defaultValues: { ...defaultValues, ...currentEditFtr },
  });
  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    setValue,
    errors,
  } = methods;
  const {
    id,
    isCarbonCredit: defaultIsCarbon,
    cid,
    fieldIds,
    subfieldIds,
    beginOnUtc,
    endOnUtc,
    conservationTypeId,
    conservationStatusId,
  } = watch();
  const [isCarbonCredit, setIsCarbonCredit] = useState(defaultIsCarbon);

  async function handleSave(d: ConservationPost) {
    const body = { ...d };
    body.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    if (!body.fieldIds && !body.subfieldIds && !body.geometry) {
      return;
    }
    if (!body.fieldIds?.length) {
      delete body.fieldIds;
    } else {
      body.fieldIds = JSON.parse(body.fieldIds);
    }
    if (!body.subfieldIds?.length) {
      delete body.subfieldIds;
    } else {
      body.subfieldIds = JSON.parse(body.subfieldIds);
    }
    // if (!body.fieldIds) {
    //   delete body.fieldIds;
    // }
    // if (!body.subfieldIds) {
    //   delete body.subfieldIds;
    // }
    const res = await saveConservationArea(body);
    if (!res.isError) {
      reset({ ...defaultValues, ...body });
      queryClient.invalidateQueries({
        queryKey: ["organization", org, "conservation", "summary"],
      });
      history.push(`${rootUrl}/inventory/conservation`);
    }
    return res;
  }

  const onSave = async (d: ConservationPost) => {
    try {
      const startInput = new Date(d.beginOnUtc);
      const endInput = new Date(d.endOnUtc);
      const inSeasons = seasons.filter((i) => {
        const seasonStart = new Date(i.beginOnUtc);
        const seasonEnd = new Date(i.endOnUtc);
        return dateRangeOverlaps(startInput, endInput, seasonStart, seasonEnd);
      }, false);
      if (inSeasons?.length) {
        const currSeasonId = season?.id;
        const ids = inSeasons?.map((s) => s.id);
        if (!ids.includes(currSeasonId)) {
          // TODO: lang
          setShowSeasonAlert({
            data: d,
            message: (
              <>
                <Alert severity="info">
                  * NOTE: This conservation area falls outside of your current
                  season, but would fall under the following:
                  <div>
                    {inSeasons
                      .map((s) => s.name)
                      .sort()
                      .join(",")}
                  </div>
                </Alert>
              </>
            ),
          });
          return null;
        }
        // if it intersects the current season, go ahead and save
        handleSave(d);
      } else {
        setShowSeasonAlert({
          data: d,
          message: (
            <Alert severity="error">
              * WARNING: This conservation area falls outside of all existing
              seasons and you will not be able to view it.
            </Alert>
          ),
        });
      }
      return inSeasons;
    } catch (e) {
      console.error("Failed trying to submit");
      return e;
    }
  };
  return (
    <FormWrapper
      methods={methods}
      defaultValues={defaultValues}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={`${rootUrl}/inventory/conservation`}
      geometryRequired={false}
      saveState={saveState}
      deleteState={deleteState}
      onDelete={async () => {
        const res = await deleteConservationArea(id);
        if (!res.isError) {
          reset(defaultValues);
          queryClient.invalidateQueries({
            queryKey: ["organization", org, "conservation", "summary"],
          });
          history.push(`${rootUrl}/inventory/conservation`);
        }
      }}
      onSubmit={handleSubmit(onSave)}
    >
      {!editId ? (
        <FieldsAndSubfields
          control={control}
          ids={{
            fieldIds: parseIdsJSON(fieldIds),
            subfieldIds: parseIdsJSON(subfieldIds),
          }}
        />
      ) : null}
      <Stack spacing={2}>
        <input ref={register} required type="hidden" name="gpsSourceTypeId" />
        <TextField
          inputProps={{
            name: "cid",
            maxLength: 255,
            ref: register,
          }}
          value={cid ?? ""}
          label={"ID"}
          fullWidth
        />
        <DateRangeInputs
          required
          control={control}
          setValue={setValue}
          startProp="beginOnUtc"
          endProp="endOnUtc"
          startDate={beginOnUtc ? new Date(beginOnUtc) : null}
          endDate={endOnUtc ? new Date(endOnUtc) : null}
        />
        <Controller
          name="conservationTypeId"
          control={control}
          rules={{ required: true }}
          render={(props: { onChange: (_id: string) => void }) => (
            <Autocomplete
              name="conservationTypeId"
              label={`${t("common.type")} *`}
              options={typesQ?.data}
              value={
                typesQ?.data?.find((f) => f.id === conservationTypeId) ?? null
              }
              onChange={(_e, item) => {
                const opt = item as { id: string };
                props.onChange(opt?.id);
              }}
              error={errors?.conservationTypeId}
              InputProps={{ required: true }}
            />
          )}
        />
        <Controller
          name="conservationStatusId"
          control={control}
          rules={{ required: true }}
          render={(props: { onChange: (_id: string) => void }) => (
            <Autocomplete
              label={`${t("common.status")} *`}
              name="conservationStatusId"
              options={statusesQ?.data}
              value={
                statusesQ?.data?.find((f) => f.id === conservationStatusId) ??
                null
              }
              onChange={(_e, item) => {
                const opt = item as { id: string };
                props.onChange(opt?.id);
              }}
              error={errors?.conservationStatusId}
              InputProps={{ required: true }}
            />
          )}
        />
        <FormControlLabel
          sx={{ textTransform: "uppercase" }}
          control={
            <Switch
              name="isCarbonCredit"
              checked={
                (isCarbonCredit !== undefined
                  ? isCarbonCredit
                  : defaultIsCarbon) ?? false
              }
              onChange={(e) => {
                setIsCarbonCredit(e.target.checked);
              }}
              inputRef={register}
              inputProps={{
                name: "isCarbonCredit",
                "aria-label": t("inventory.conservation.applyToCC"),
              }}
            />
          }
          label={t("inventory.conservation.applyToCC")}
        />
      </Stack>

      <div ref={alertRef} />
      <ConfirmationDialog
        open={Boolean(showSeasonAlert)}
        title={t("common.confirm")}
        message={
          <Stack gap={2}>
            {showSeasonAlert?.message}
            <Typography sx={{ ml: "auto" }}>
              {t("common.areYouSure")}
            </Typography>
          </Stack>
        }
        onClose={async (confirmed) => {
          if (confirmed) {
            handleSave(showSeasonAlert.data);
            setShowSeasonAlert(undefined);
          } else {
            setShowSeasonAlert(undefined);
          }
        }}
      />
    </FormWrapper>
  );
}
