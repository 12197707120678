import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import pkg from "../package.json";

const availableLanguages = ["en", "es", "fr"];

function getPrefLang() {
  try {
    const prefLng = window.localStorage.getItem("cai:lang:preference");
    const browserLng = navigator?.language?.split("-")[0];
    const supportedBrowserLng = availableLanguages.includes(browserLng);
    // use the localStorage preference, or the browser preference if exists
    // in our available languages, else default to english
    const final = prefLng || (supportedBrowserLng ? browserLng : "en");
    return final;
  } catch (e) {
    console.error("Failed getting language preference");
    return "en";
  }
}

const languageDetector = new LanguageDetector();
const customDetector = {
  name: "customDetector",
  lookup() {
    return getPrefLang();
  },
  // cacheUserLanguage(lng, options) {
  //   // options -> are passed in options
  //   // lng -> current language, will be called after init and on changeLanguage
  //   // store it
  // },
};
languageDetector.addDetector(customDetector);

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: {
      order: ["customDetector"],
      // prevent i18n from saving to localStorage (handling it ourselves)
      caches: [],
    },
    fallbackLng: "en",
    // debug: true,
    whitelist: availableLanguages,
    react: {
      useSuspense: true,
    },
    // prevent cache on version updates by tagging language files
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?${pkg?.version}`,
    },
  });

export default i18n;
