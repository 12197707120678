import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import DataConnections from "./DataConnections";
import PrivacyAndTOS from "views/PrivacyAndTOS";
import pkgjson from "../../../package.json";
import { useAccountProfile } from "api/useAccountProfile";
import { useOrgState } from "providers";
import { ThemeSwitch } from "lib/theme";
import {
  useSettingsState,
  useSettingsDispatch,
} from "providers/SettingsProvider";
import Autocomplete from "lib/Select/Autocomplete";
import { DataFiltersTable } from "./DataFiltersTable";
import { YieldFilter } from "./YieldFilter";
import { useCropTypes } from "api/lookups/useCropTypes";
import { useYieldFilters } from "api/useYieldFilters";
import { Grid } from "components/layout/Grid";
import { SectionHeading } from "./SectionHeading";

function ProfileItem({ label, value }: { label: string; value: string }) {
  return (
    <div style={{ display: "flex" }}>
      <dt
        style={{
          marginRight: "0.25rem",
          textTransform: "uppercase",
          fontWeight: "normal",
        }}
      >
        {label}:
      </dt>
      <dd>{value || "--"}</dd>
    </div>
  );
}

export default function SettingsView() {
  const { user } = useAuth0();
  const { org, season } = useOrgState();
  const { theme, language, languages } = useSettingsState();
  const saveSettings = useSettingsDispatch();
  const { t } = useTranslation();
  const [showVersion, setShowVersion] = useState<boolean>();
  const accountProfileQuery = useAccountProfile(org);
  const cropTypesQ = useCropTypes(org);
  const yieldFiltersQ = useYieldFilters({ org, seasonId: season?.id });

  return (
    <Box style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box style={{ alignItems: "center", display: "flex" }}>
        <Typography
          sx={{ mb: 1 }}
          component="h2"
          variant="h5"
          onClick={(e: React.MouseEvent) => {
            if (e.ctrlKey) {
              setShowVersion(!showVersion);
            }
          }}
        >
          {t("settings.title")}
        </Typography>
        {showVersion ? (
          <div style={{ marginLeft: "auto" }}>v{pkgjson.version}</div>
        ) : null}
      </Box>
      <Grid height="100%" container>
        <Grid height="100%" xl={5}>
          <Stack sx={{ overflow: "auto", height: "100%" }} spacing={2}>
            <Paper sx={{ p: 3, display: "flex", flexDirection: "column" }}>
              <SectionHeading>{t("settings.profile")}</SectionHeading>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap-reverse",
                  overflow: "auto",
                }}
              >
                <div style={{ flexGrow: 1 }}>
                  <dl
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 0,
                    }}
                  >
                    <ProfileItem label={t("common.name")} value={user?.name} />
                    <ProfileItem
                      label={t("settings.email")}
                      value={user?.email}
                    />
                    <ProfileItem
                      label={t("user.role")}
                      value={accountProfileQuery.data?.role}
                    />
                    <ProfileItem
                      label={t("account.license")}
                      value={accountProfileQuery.data?.license}
                    />
                  </dl>
                </div>
                <div style={{ width: "8rem" }}>
                  <img
                    style={{ width: "100%", borderRadius: "50%" }}
                    // referrerPolicy to fix 403 error on localhost
                    referrerPolicy="no-referrer"
                    alt="Profile image"
                    src={user.picture}
                  />
                </div>
              </div>
              <Grid container>
                <Grid md={6} display={"flex"}>
                  <PrivacyAndTOS linksOnly />
                </Grid>
                {accountProfileQuery.data?.accountUrl ? (
                  <Grid display={"flex"} md={6}>
                    <Link
                      underline="none"
                      component="a"
                      sx={{
                        ml: "auto",
                        mt: "auto",
                        color: theme === "dark" ? "warning.main" : "",
                        "&:hover": {
                          color: theme === "dark" ? "warning.main" : "",
                        },
                      }}
                      href={accountProfileQuery.data?.accountUrl}
                    >
                      {t("settings.manageAccount")}
                    </Link>
                  </Grid>
                ) : null}
              </Grid>
            </Paper>
            <Paper sx={{ p: 3 }}>
              <SectionHeading>{t("settings.preferences")}</SectionHeading>
              <Autocomplete
                label={t("settings.language")}
                value={languages.find((l) => l.value === language) ?? ""}
                id="language-select"
                options={languages}
                onChange={(_e, item) => {
                  const opt = item as { value: string };
                  saveSettings({
                    language: opt.value,
                  });
                }}
              />
              <ThemeSwitch
                sx={{ mt: 2 }}
                checked={theme === "dark"}
                onChange={(e) => {
                  saveSettings({
                    theme: !e.target.checked ? "light" : "dark",
                  });
                }}
              />
            </Paper>
            <Paper sx={{ p: 3, flexGrow: 1 }}>
              <SectionHeading>{t("settings.yieldDataFilters")}</SectionHeading>
              <Switch>
                <Route path="/:org/:season/settings/filters/add">
                  <Box sx={{ mb: 1, alignItems: "center", display: "flex" }}>
                    <Typography>{t("common.add")}</Typography>
                    <IconButton
                      sx={{
                        ml: "auto",
                      }}
                      href={`../../settings`}
                    >
                      <ArrowBackOutlinedIcon />
                    </IconButton>
                  </Box>
                  <YieldFilter
                    cropTypes={cropTypesQ.data?.map((i) => {
                      if (
                        yieldFiltersQ.data?.find((yf) => yf.cropTypeId === i.id)
                      ) {
                        return { disabled: true, ...i };
                      }
                      return { ...i };
                    })}
                    cancelHref="../../settings"
                  />
                </Route>
                <Route path="/:org/:season/settings/filters/edit/:itemId">
                  <Box sx={{ mb: 1, alignItems: "center", display: "flex" }}>
                    <Typography>{t("common.edit")}</Typography>
                    <IconButton
                      sx={{
                        ml: "auto",
                      }}
                      href={`../../../settings`}
                    >
                      <ArrowBackOutlinedIcon />
                    </IconButton>
                  </Box>
                  <YieldFilter
                    cropTypes={cropTypesQ.data}
                    cancelHref="../../../settings"
                  />
                </Route>
                <Route
                  path="/:org/:season/settings"
                  component={DataFiltersTable}
                />
              </Switch>
            </Paper>
          </Stack>
        </Grid>
        <Grid height="100%" overflow={"auto"} xl={7}>
          <DataConnections />
        </Grid>
      </Grid>
    </Box>
  );
}
