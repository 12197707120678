import { useCallback, useEffect } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { DownloadCloud, UploadCloud } from "react-feather";
import { useOrgState, useOrgDispatch } from "providers";
import { DataTable } from "components";
import jdImg from "./deere-logo.png";
import { SectionHeading } from "../SectionHeading";

interface ISharedData {
  field: string;
  farm?: string;
  applied?: boolean;
  planted?: boolean;
  harvested?: boolean;
  tilled?: boolean;
}
interface ISharedDataByTenant {
  tenant: string;
  data: ISharedData[];
  darkLogo?: HTMLImageElement;
  lightLogo?: HTMLImageElement;
}

function BooleanCell({ value }: { value: string }) {
  if (value) {
    return <input readOnly type="checkbox" checked style={{ opacity: 0.5 }} />;
  }
  return (
    <input readOnly type="checkbox" checked={false} style={{ opacity: 0.5 }} />
  );
}

export default function DataConnections() {
  const { t } = useTranslation();
  const { org, seasonId } = useOrgState();
  const { getEnterpriseLogos } = useOrgDispatch();
  const theme = useTheme();
  const {
    fetchData: fetchDataSharing,
    state: sharedOperationsState,
  } = useFetchWithToken<ISharedDataByTenant[]>();

  // fetch dark/light photos for each tenant
  // and store it with the other data
  const getLogos = useCallback(
    async (d: ISharedDataByTenant) => {
      const [dark, light] = await getEnterpriseLogos(d.tenant);
      return { ...d, darkLogo: dark.data, lightLogo: light.data };
    },
    [getEnterpriseLogos]
  );

  const sharedCellProps = {
    Cell: BooleanCell,
    disableGlobalFilter: true,
  };
  // NOTE: added this to show that we ingest data from JD
  // TODO: need to remove this
  const receivingCols = ["folds", "templetonff", "rowcrop"].includes(org)
    ? [
        {
          Header: "",
          styles: { width: "150px" },
          isSortable: false,
          accessor: "img",
          Cell: () => {
            return <img src={jdImg} />;
          },
        },
        {
          Header: "",
          accessor: "description",
          isSortable: false,
          Cell: () => {
            return (
              <div>
                <dl style={{ marginBottom: 0 }}>
                  <div style={{ display: "flex" }}>
                    <dt>Inventory:</dt>
                    <dd style={{ marginBottom: 0, marginLeft: "0.5rem" }}>
                      Field boundaries
                    </dd>
                  </div>
                  <div style={{ display: "flex" }}>
                    <dt>Operations:</dt>
                    <dd style={{ marginBottom: 0, marginLeft: "0.5rem" }}>
                      Planted, Applied, Harvested, Tilled
                    </dd>
                  </div>
                  <div style={{ display: "flex" }}>
                    <dt>Subfields:</dt>
                    <dd style={{ marginBottom: 0, marginLeft: "0.5rem" }}>
                      RX
                    </dd>
                  </div>
                </dl>
              </div>
            );
          },
        },
      ]
    : [];

  const receivingData = ["folds", "templetonff", "rowcrop"].includes(org)
    ? [
        {
          img: "",
          description: "",
        },
      ]
    : [];

  useEffect(() => {
    fetchDataSharing(`/${org}/sharing/season/${seasonId}`, {
      transformResponse: async (data: ISharedDataByTenant[]) => {
        const withIcons = data.map((d) => {
          return getLogos(d);
        });
        return Promise.all(withIcons);
      },
    });
  }, [fetchDataSharing, getLogos, org, seasonId]);

  return (
    <Paper sx={{ p: 3 }} style={{ height: "100%", overflow: "auto" }}>
      <SectionHeading>{t("settings.dataConnections")}</SectionHeading>
      <Box sx={{ mb: 3, table: { thead: { display: "none" } } }}>
        <Typography
          style={{ display: "flex", alignItems: "center" }}
          variant="h6"
          component="h4"
        >
          {t("settings.receiving")}
          <Typography sx={{ ml: 1 }} component="span" color="primary.main">
            <DownloadCloud />
          </Typography>
        </Typography>
        <DataTable
          isFilterable={false}
          columns={receivingCols}
          data={receivingData}
        />
      </Box>
      <Typography
        style={{ display: "flex", alignItems: "center" }}
        variant="h6"
        component="h4"
      >
        {t("settings.sending")}
        <Typography sx={{ ml: 1 }} component="span" color="primary.main">
          <UploadCloud />
        </Typography>
      </Typography>
      <p>{t("settings.contactMgrDataShare")}</p>
      <div style={{ overflow: "auto" }}>
        {!sharedOperationsState.data?.length ? (
          <DataTable
            isLoading={sharedOperationsState.isLoading}
            isFilterable={false}
            data={[]}
          />
        ) : null}
        {sharedOperationsState.data?.map((y: ISharedDataByTenant, idx) => (
          <>
            <div key={y.tenant}>
              <DataTable
                components={{
                  HeaderLeft: (
                    <div style={{ width: "170px" }}>
                      <img
                        style={{ maxWidth: "100%", height: "auto" }}
                        src={
                          theme.palette.mode === "light"
                            ? y.lightLogo?.src
                            : y.darkLogo?.src
                        }
                      />
                    </div>
                  ),
                }}
                columns={[
                  {
                    Header: t("common.field"),
                    accessor: "field",
                    Cell: function FarmField({
                      value,
                      row,
                    }: {
                      value: string;
                      row: { original: ISharedData };
                    }) {
                      return (
                        <Box
                          sx={{ mr: 1 }}
                          style={{
                            float: "left",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span>{`${value}`}</span>
                          <small>{row?.original?.farm}</small>
                        </Box>
                      );
                    },
                  },
                  {
                    Header: t("common.planted"),
                    accessor: "planted",
                    ...sharedCellProps,
                  },
                  {
                    Header: t("common.applied"),
                    accessor: "applied",
                    ...sharedCellProps,
                  },
                  {
                    Header: t("common.harvested"),
                    accessor: "harvested",
                    ...sharedCellProps,
                  },
                  {
                    Header: t("common.tilled"),
                    accessor: "tilled",
                    ...sharedCellProps,
                  },
                  {
                    Header: t("inventory.damage.title"),
                    accessor: "damage",
                    ...sharedCellProps,
                  },
                  {
                    Header: t("common.carbon"),
                    accessor: "carbon",
                    ...sharedCellProps,
                  },
                ]}
                data={y.data || []}
              />
            </div>
            {sharedOperationsState.data.length - 1 !== idx ? (
              <Divider sx={{ my: 3 }} />
            ) : null}
          </>
        ))}
      </div>
    </Paper>
  );
}
