import { useEffect } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import { useTranslation } from "react-i18next";
import { ISettingsConfig, ISettingsLangs } from "types";

const defaults = {
  theme: "dark",
  language: navigator?.language?.split("-")[0] || "en",
  unitSystem: "imperial",
} as ISettingsConfig;
export default function useSettings() {
  const { state, setDataState } = useFetchWithToken<ISettingsConfig>();
  const { t } = useTranslation();

  const languages = [
    { label: t("settings.english"), value: "en" },
    { label: t("settings.spanish"), value: "es" },
    { label: t("settings.french"), value: "fr" },
  ] as ISettingsLangs;

  useEffect(() => {
    if (!state.data && !state.isLoading && !state.isError) {
      if (window.localStorage) {
        const currentSettings = window.localStorage.getItem(
          "cai-agassessor-settings"
        );
        if (currentSettings) {
          try {
            setDataState(JSON.parse(currentSettings));
          } catch (e) {
            //
          }
        } else {
          setDataState(defaults);
        }
      } else {
        setDataState(defaults);
      }
    }
  }, [state, setDataState]);

  return {
    reset: () => {
      setDataState(null);
    },
    state,
    languages,
    language: state?.data?.language,
    saveSettings: (data: ISettingsConfig) => {
      if (window.localStorage) {
        window.localStorage.setItem(
          "cai-agassessor-settings",
          JSON.stringify(data)
        );
        window.localStorage.setItem("cai:lang:preference", data.language);
      }
      setDataState(data);
    },
  };
}
