﻿import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import "regenerator-runtime/runtime";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { AppProvider } from "providers/AppProvider";
import "./i18n";
import AuthView from "./views/AuthView";
import AppLoading from "./views/AppLoading";
import AxiosInterceptor from "api/AxiosInterceptor";
import { SettingsProvider } from "providers";
import Routes from "./Routes";
import "./app.scss";

const customHistory = createBrowserHistory();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // don't retry on dev (default is 3)
      retry: process.env.NODE_ENV !== "development" ? 3 : false,
    },
  },
});
window.REACT_APP_API_ORIGIN = process.env.REACT_APP_API_ORIGIN;
window.REACT_APP_TILESERVER_ORIGIN = process.env.REACT_APP_TILESERVER_ORIGIN;
window.REACT_APP_MAPBOX_API_TOKEN = process.env.REACT_APP_MAPBOX_API_TOKEN;

const domain =
  typeof process.env.REACT_APP_AUTH0_DOMAIN !== "undefined"
    ? process.env.REACT_APP_AUTH0_DOMAIN
    : null;
const clientId =
  typeof process.env.REACT_APP_AUTH0_CLIENT_ID !== "undefined"
    ? process.env.REACT_APP_AUTH0_CLIENT_ID
    : null;
const audience =
  typeof process.env.REACT_APP_AUTH0_AUDIENCE !== "undefined"
    ? process.env.REACT_APP_AUTH0_AUDIENCE
    : null;

ReactDOM.render(
  <React.Suspense
    fallback={
      <AuthView>
        <AppLoading />
      </AuthView>
    }
  >
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <AxiosInterceptor />
        <Router history={customHistory}>
          <AppProvider>
            <SettingsProvider>
              <Routes />
            </SettingsProvider>
          </AppProvider>
        </Router>
      </QueryClientProvider>
    </Auth0Provider>
  </React.Suspense>,
  document.getElementById("root")
);
