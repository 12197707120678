import React from "react";
import agLogoDarkText from "../images/agassessor-with-logo-dark.png";
import agLogolightText from "../images/agassessor-with-logo-light.png";
import { useTheme } from "@mui/material";

export default function AuthView({ children }: { children: React.ReactNode }) {
  // const { theme } = useSettingsState();
  const theme = useTheme();
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "10rem",
      }}
    >
      <img
        alt="AgAssessor logo"
        style={{ marginBottom: "1rem", width: "300px" }}
        src={theme.palette.mode === "light" ? agLogoDarkText : agLogolightText}
      />
      {children}
    </div>
  );
}
